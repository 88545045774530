var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "UploadVideoModal",
      attrs: {
        centered: "",
        closeOnBackdrop: false,
        show: _vm.show,
        title: _vm.$t("lk.videoEncrypt.UploadVideoModal.title"),
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("CInputFile", {
            ref: "uploadvideo",
            attrs: {
              label: _vm.$t("lk.videoEncrypt.UploadVideoModal.file"),
              accept: ".mp4",
            },
            on: { change: _vm.onChangeInputFile },
          })
        : _vm._e(),
      !_vm.isPublic
        ? _c("CSelect", {
            attrs: {
              label: _vm.$t("lk.videoEncrypt.UploadVideoModal.type"),
              value: _vm.type,
              options: ["private", "public"],
            },
            on: {
              "update:value": function ($event) {
                _vm.type = $event
              },
            },
          })
        : _vm._e(),
      _vm.progressEnable
        ? _c(
            "CProgress",
            { staticClass: "mb-3" },
            [
              _c(
                "CProgressBar",
                {
                  attrs: {
                    color: _vm.progressColor,
                    value: _vm.progress,
                    variant: "stripped",
                    animated: "",
                  },
                },
                [_vm._v(_vm._s(_vm.progress.toFixed(2)) + "%")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.errorText
        ? _c("p", {
            staticClass: "alert alert-danger",
            domProps: { innerHTML: _vm._s(_vm.errorText) },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "UploadVideoModal__btnBlock" },
        [
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "shadow-none UploadVideoModal__btn",
              attrs: {
                color: "success",
                loading: _vm.isLoading,
                disabled: !_vm.file || _vm.isLoading || _vm.disabledForm,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.upload.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("lk.videoEncrypt.UploadVideoModal.btnUpload")) +
                  " "
              ),
            ]
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none UploadVideoModal__btn",
              attrs: { color: "primary", disabled: _vm.isLoading },
              on: { click: _vm.close },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("lk.videoEncrypt.UploadVideoModal.btnCancel")) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }