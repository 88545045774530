<template>
  <CModal
    centered
    :closeOnBackdrop="false"
    :show="show"
    :title="$t('lk.videoEncrypt.UploadVideoModal.title')"
    @update:show="close"
    class="UploadVideoModal"
  >
    <CInputFile
      v-if="!isLoading"
      :label="$t('lk.videoEncrypt.UploadVideoModal.file')"
      accept=".mp4"
      @change="onChangeInputFile"
      ref="uploadvideo"
    />
    <CSelect
      v-if="!isPublic"
      :label="$t('lk.videoEncrypt.UploadVideoModal.type')"
      :value.sync="type"
      :options="['private', 'public']"
    />
    <CProgress class="mb-3" v-if="progressEnable">
      <CProgressBar :color="progressColor" :value="progress" variant="stripped" animated>{{ progress.toFixed(2) }}%</CProgressBar>
    </CProgress>
    <p class="alert alert-danger" v-if="errorText" v-html="errorText"></p>
    <div class="UploadVideoModal__btnBlock">
      <CLoadingButtonCustom
        class="shadow-none UploadVideoModal__btn"
        color="success"
        :loading="isLoading"
        :disabled="!file || isLoading || disabledForm"
        @click.native="upload"
      >
        {{$t('lk.videoEncrypt.UploadVideoModal.btnUpload')}}
      </CLoadingButtonCustom>
      <CButton class="shadow-none UploadVideoModal__btn"
               color="primary"
               :disabled="isLoading"
               @click="close">
        {{$t('lk.videoEncrypt.UploadVideoModal.btnCancel')}}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios';
import {textErrorFromResponse} from "@/assets/js/axios/responseErrorHandler";

export default {
  name: 'UploadVideoModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    uri: {
      type: String,
      default: ''
    },
    isPublic: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: false,
      progress: 0,
      progressUuid: null,
      progressEnable: false,
      progressColor: 'success',
      disabledForm: true,
      file: null,
      errorText: '',
      type: 'private',
      uploadRoute: this.isPublic ? '/api/v1/video-public' : '/api/v1/video-encrypt'
    }
  },
  methods: {
    onChangeInputFile(files) {
      this.progressEnable = false
      this.errorText = ''
      if (files && files.length > 0) {
        this.file = files[0]
        this.disabledForm = false
      }else{
        this.file = null
        this.disabledForm = true
        this.$refs.uploadvideo.$el.getElementsByTagName('input')[0].value = null
      }
      this.errorText = ''
    },
    upload() {
      if(this.isLoading || !this.file){
        return
      }
      this.progress = 0
      this.progressUuid = this.uuid()
      this.progressColor = 'success'
      this.progressEnable = true
      this.errorText = ''
      this.isLoading = true
      let formData = new FormData()
      formData.append('video', this.file)
      if(!this.isPublic){
        formData.append('types', this.type)
      }
      axios.post(this.uri + this.uploadRoute + '?X-Progress-ID=' + this.progressUuid,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.$toastr.success(this.$t('lk.videoEncrypt.UploadVideoModal.uploaded'))
          this.$emit('uploaded')
          this.close()
        })
        .catch(e => {
          this.progressColor = 'danger'
          if(e.response){
            if(this.progress < 1){
              this.progressEnable = false
            }
            this.errorText = textErrorFromResponse(e.response)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
      this.getProgress(1000)
    },
    getProgress(delay) {
      if(!this.isLoading || !this.progressEnable){
        return
      }
      setTimeout(() => {
        axios.get(this.uri + '/progress?X-Progress-ID=' + this.progressUuid)
          .then(res => {
            if(!res.data || !res.data.state){
              return
            }
            if(res.data.state === 'done'){
              this.progress = 100
            }else if(res.data.size){
              this.progress = (res.data.received || 0) / res.data.size * 100
            }
          })
          .finally(() => {
            if(!this.isLoading || !this.progressEnable){
              return
            }
            this.getProgress(3000)
          })
      }, delay)
    },
    close() {
      setTimeout(() => {//иначе не закрывало после окончания загрузки
        this.$emit('close')
        this.file = null
        this.disabledForm = true
        this.errorText = ''
        this.progressEnable = false
        this.$refs.uploadvideo.$el.getElementsByTagName('input')[0].value = null
      })
    },
    uuid() {
      let uuid = ''
      for (let i = 0; i < 32; i++) {
        uuid += Math.floor(Math.random() * 16).toString(16)
      }
      return uuid
    }
  }
};
</script>

<style lang="scss" scoped>
.UploadVideoModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>
